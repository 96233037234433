/**
* Setup webpack public path
* to enable lazy-including of
* js chunks
*
*/
/* ----global urls:true---- */
import 'bootstrap';

const $ = window.jQuery;


$(() => {
	// Submit filter form on checkbox change
	$('.form.form--filter').on('change', '.form__check-input', (e) => {
		const form = $(e.currentTarget).closest('form');

		form && $(form).submit();
	});
});

$(window).resize(() => {

});

